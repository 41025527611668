import * as React from 'react';
import {Link} from 'react-router-dom';

import {Carousel, Button} from 'antd';

import {Veterinarian} from '@app/objects/Veterinarian';
import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';
import SliderButtons from '@app/components/LocalCommon/SliderButtons/SliderButtons';
import {SliderVetProps} from '@app/components/UI/Slider/SliderVet';
import ModalAreaVet from '@app/components/UI/ModalAreaVet/ModalAreaVet';
import listenerForBrowser from '@app/components/Pages/listenerForBrowser';
import {getImagePath} from '@app/objects/Image';

const arrowIcon = require('@app/images/arrow_circle.svg');

const SliderVetCarousel: React.FC<SliderVetProps> = (props) => {
	const slider = React.useRef<any>();
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const [slidesToShow, setSlidesToShow] = React.useState(1);
	const [navCollapse, setNavCollapse] = React.useState(false);
	const [items, setItems] = React.useState<Veterinarian[]>(props.items);
	const [currentVet, setCurrentVet] = React.useState<Veterinarian>();
	const [visible, setVisible] = React.useState<boolean>(false);

	React.useEffect(() => {
		props.items.length < 3 ? setItems(props.items) : setItems(props.items.concat(props.items));
	}, [props.items]);

	React.useEffect(() => {
		items.length >= 3 ? setSlidesToShow(3) : setSlidesToShow(1);
	}, [items]);

	React.useEffect(() => {
		setCurrentVet(items[currentIndex]);
	}, [items, currentIndex]);

	React.useEffect(() => {
		const mql = window.matchMedia('screen and (max-width: 768px)');
		const listenerFunc = () => {
			if (mql.matches) {
				setNavCollapse(true);
				props.items.length !== 1  ? setItems(props.items.concat(props.items)) : setItems(props.items);
				props.items.length !== 1  ? setSlidesToShow(3) : setSlidesToShow(1);

			} else {
				setNavCollapse(false);
				items.length >= 3 ? setSlidesToShow(3) : setSlidesToShow(1);
				props.items.length < 3 ? setItems(props.items) : setItems(props.items.concat(props.items));
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}, []);

	return (
		<>
			<div className="slider-vet-body__left">
				<article className="vet-block">
					{currentVet &&
						<>
							<header className="vet-block-header">
								<img className="vet-block-header__img" src={getImagePath(currentVet.avatar, undefined, 325)} alt={`Dr. ${currentVet.firstName}`}/>
								<div>
									<h4 className="vet-block-header__name">{`Dr. ${currentVet.firstName}`}</h4>
									<p className="vet-block-header__location">{props.teleadviceCapture ? props.teleadviceCapture : currentVet.areaName}</p>
								</div>
							</header>
							<div className="vet-block__text" dangerouslySetInnerHTML={{__html: currentVet.biography}} />
							{!props.hideReadMore && currentVet.areaUrl ?
								<LinkMore
									linkMoreCaption="Read more"
									linkMoreLink={{
										pathname: `/find-a-vet/${currentVet.area.state?.replace(' ', '-')}/${currentVet.areaUrl}/veterinarians`,
										state: {currentVetId: currentVet.id}
									}}
								/> :
								<Button className="link-more slider-vet-btn" onClick={() => { setVisible(true); }}>
									<span>Read more</span>
									<img src={arrowIcon} alt="arrow" className="link-more-img"/>
								</Button>
							}
								<ModalAreaVet
									vet={currentVet}
									visible={visible}
									onOk={() => setVisible(false)}
									onCancel={() => setVisible(false)}
								/>
						</>
					}
				</article>
			</div>
			<div className={props.items.length !== 1 ? 'slider-vet-body__right' : 'slider-vet-body__right slider-vet-body__right_single'}>
				<Carousel
					ref={slider}
					slidesToShow={slidesToShow}
					afterChange={setCurrentIndex}
					lazyLoad="ondemand"
					dots={false}
					infinite
					swipeEvent={swipeDirection => swipeDirection === 'left' ? slider.current.next() : slider.current.prev()}
				>
					{items.map((item: Veterinarian) => (
						item.areaUrl ?
						<Link
							to={{
								pathname: `/find-a-vet/area/${item.areaUrl}/veterinarians`,
								state: {currentVetId: item.id}
							}}
							key={item.id}
						>
							<img
								src={getImagePath(item.avatar, undefined, 325)}
								alt={'Doctor'}
								className={slidesToShow >= 3 ? 'slider-vet-item' : 'slider-vet-item slider-vet-item_color'}
								key={item.id}
							/>
						</Link> :
							<img
								src={getImagePath(item.avatar, undefined, 325)}
								alt={'Doctor'}
								className={slidesToShow >= 3 ? 'slider-vet-item' : 'slider-vet-item slider-vet-item_color'}
								key={item.id}
							/>
					))}
				</Carousel>
				{
					slidesToShow >= 3 ?
						<SliderButtons slider={slider} />
						: null
				}
			</div>
		</>
	);
};

export default SliderVetCarousel;
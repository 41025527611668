import React, { useState } from 'react';

import Modal from 'antd/lib/modal';

import SliderAreaVet from '@app/components/UI/Slider/SliderAreaVet';
import {bodyOverflowTransform} from '@app/components/LocalCommon/Utils/bodyOverflowTransform';
import listenerForBrowser from '@app/components/Pages/listenerForBrowser';

import '@app/scss/components/area.scss';

const ModalAreaVet = ({vet, visible, onOk, onCancel}) => {
	const [width, setWidth] = React.useState<string>('70%');

	bodyOverflowTransform(true);

	React.useEffect(() => {
		const mql = window.matchMedia('screen and (max-width: 768px)');

		const listenerFunc = () => {
			if (mql.matches) {
				setWidth('100vw');
			} else {
				setWidth('70%');
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}, []);

	return <Modal
		visible={visible}
		footer={false}
		onCancel={onCancel}
		width={width}
		wrapClassName="modal-wrap"
		className="modal-area-vet"
	>
		<SliderAreaVet vet={vet} key={vet.id}/>
	</Modal>;
};

export default ModalAreaVet;
import * as React from 'react';

import {LinkBlockItemIcon, LinkBlockItemRich} from '@app/objects/LinkBlockItem';
import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';

export const homeLinkItems: LinkBlockItemIcon[] = [
	{
		path: '/our-services/in-home-euthanasia',
		name: <>In-home<br/>euthanasia</>,
		src: require('@app/images/link-icons/euthanasia.svg'),
		id: 'In-home euthanasia',
	},
	{
		path: '/quality-of-life-assessment',
		name: 'Quality-of-life assessment',
		src: require('@app/images/link-icons/dog_green.svg'),
		id: 'Quality-of-life assessment',
	},
	{
		path: '/veterinarian-testimonials',
		name: 'Read testimonials',
		src: require('@app/images/link-icons/vet.svg'),
		id: 'Read testimonials',
	},
	{
		path: '/pet-memorial',
		name: 'View our pet memorial center',
		src: require('@app/images/link-icons/candel.svg'),
		id: 'View our pet memorial center',
	},
];

export const homeLinkRichTitle = <>We’re here to <span className="highlight">support</span> you when you need it</>;

export const homeLinkRichItems: LinkBlockItemRich[] = [
	{
		icon: require('@app/images/in-home.svg'),
		name: 'In-Home Euthanasia',
		text: 'At Lap of Love, we take the utmost pride in helping your pet experience a peaceful passing in the comfort and tranquility of their home.',
		path: '/our-services/in-home-euthanasia',
		alt: 'House heart icon',
	},
	{
		icon: require('@app/images/vet.svg'),
		name: 'Veterinary Hospice',
		text: 'Family-focused, in-home hospice care ensures your dog or cat is comfortable and happy during their final days.',
		path: '/our-services/veterinary-hospice',
		alt: 'Veterinarian stethoscope',
	},
	{
		icon: require('@app/images/aftercare.svg'),
		name: 'Aftercare',
		text: 'From cremation to personal keepsakes, our aftercare services allow you to forever memorialize your beloved dog or cat.',
		path: '/our-services/aftercare',
		alt: 'Paw icon',
	},
	{
		icon: require('@app/images/teleadvice.svg'),
		name: 'Telehospice',
		text: 'Personal phone/video calls, during which a Lap of Love team member will help you gain clarity around what\'s best for your ailing or aging pet.',
		path: '/our-services/telehospice',
		alt: 'Mobile phone icon',
	},
	{
		icon: require('@app/images/pet-loss-heart.png'),
		name: 'Pet Loss Support',
		text: 'Join our free, virtual Pet Loss Support Groups. Discussions are focused on healthy coping skills, grief reactions, and honoring the lives of your furry family member.',
		path: '/our-services/pet-loss-support',
		alt: 'Heart icon with paw bandage',
	},
];

export const homeSliderVetTitle = <>Helping you and your pet is our <span className="highlight">passion</span></>;
export const homeSliderTesTitle = <>In their own words</>;

export const ImageCapture = ({name, town, title}) => {
	return (
		<div>
			<span>{name}, {town}</span>
			<h4>{title}</h4>
		</div>
	);
};

export const blockRichImgCaptures = [
	{name: 'Bodhi', town: 'Yorba Linda, CA', title: 'Common Geriatric Pet Symptoms'},
	{name: 'Roger', town: 'Dumont, NJ', title: 'Blog'},
	{name: 'Axel', town: 'Paterson, NJ', title: 'How Will I Know It\'s Time'},
];

export const blockRichTitle = <>
	Informational resources to help you <span className="highlight">navigate</span> your pet’s quality of life
</>;

export const blockRichText = <>
	<LinkMore linkMoreLink={'/resource-center/common-diseases-in-dogs'} linkMoreCaption="Explore all of our resources" />
</>;
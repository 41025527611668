import * as React from 'react';

import {Veterinarian} from '@app/objects/Veterinarian';
import SliderVetCarousel from '@app/components/UI/Slider/SliderVetCarousel';

export interface SliderVetProps {
	subtitle?: string;
	title?: string | JSX.Element;
	location?: string;
	teleadviceCapture?: string;
	hideReadMore?: boolean;
	items: Veterinarian[];
}

const SliderVet: React.FC<SliderVetProps> = props => (
	<section className="slider-vet">
		{props.title && (
			<header className="slider-vet-header">
				{props.subtitle && <p className="slider-vet-header__subtitle">{props.subtitle}</p>}
				<h2 className="slider-vet-header__title">{props.title}</h2>
			</header>
		)}
		{props.location && (
			<div className="slider-vet-location">
				<img className="slider-vet-location__icon" src={require('@app/images/pin_circle.svg')} alt=""/>
				{props.location}
			</div>
		)}
		<div className="slider-vet-body">
			<SliderVetCarousel items={props.items} teleadviceCapture={props.teleadviceCapture} hideReadMore={props.hideReadMore} />
		</div>
		<div className="slider-vet__bottom-crown" />
	</section>
);

export default SliderVet;
